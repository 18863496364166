.management-header {
  font-size: 17px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.admin-input {
  border: 1px solid #ddd;
  border-radius: 10px;
  height: 30px;
  margin-right: 5px;
}
.admin-input.readonly {
  background: #ddd;
}

.admin-input.error {
  border-color: red;
}

.admin-input__meta {
  font-size: 12px;
  text-align: center;
}

.admin-input__meta.error {
  color: red;
}

.header-controls {
  border-bottom: 1px solid #2c7be5;
  padding-bottom: 5px;
}

.header-controls__button {
  cursor: pointer;
  margin-right: 10px;
  color: #f5f5f5;
  background: #2c7be5;
  border: 1px solid #2c7be5;
  padding: 5px 10px;
  border-radius: 10px;
}

.header-controls__button.active {
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: #2c7be5;
}

.admin-buckets__list {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.admin-bucket__list-header {
  border-bottom: 1px solid #737373;
}

.admin-buckets__bucket {
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
}
.admin-buckets__bucket-name {
  color: #737373;
  font-size: 18px;
  font-weight: 600;
}
