/* HELPER CLASSES */
.flex-even {
  flex: 1;
}

.wetx-remove-button {
  width: 20px;
  height: 20px;
  font-size: 13px;
  line-height: 18px;
  border: none;
  border-radius: 50%;
  color: #ffffff;
  background: #ff3131;
  font-weight: 800;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.wetx-remove-button:hover {
  background: #800020;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

/* OVERLAY */
.dropdown-file__container {
  width: 120px;
  min-width: 120px;
  margin-right: 10px;
  background-color: #edf2f9;
  height: 180px;
}

.dropdown-file__overlay-container {
  position: relative;
  height: 100%;
  padding-top: 20px;
  overflow: hidden;
}

.dropdown-file__overlay-container img {
  padding: 0 10px;
}

.dropdown-file__content {
  display: block;
  width: 100%;
}

.dropdown-file__overlay {
  position: absolute;
  top: 0;
  width: 80%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  opacity: 0;
  border-radius: 8px;
  transition: 0.5s ease;
}

.clip-image {
  background: #9d9d9d;
}

.dropdown-file__overlay-container:hover .dropdown-file__overlay {
  opacity: 1;
}

/* DEVEXTREME PAGER */
.dx-pager {
  border: 1px solid #ddd;
}
.dx-pager .dx-page-sizes {
  margin-left: 5px;
}

.dx-datagrid-headers {
  background: #eaeaea;
  color: #333333;
  font-weight: 700;
  font-size: 14px;
}

.file-table {
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 7px 14px rgba(65, 69, 88, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}
.file-table .file-header {
  border: none;
}

.dx-datagrid-borders > .dx-datagrid-filter-panel,
.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-pager {
  border: none;
}

.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers + .dx-datagrid-rowsview {
  border-top: none;
}
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-bottom: 1px solid #ddd;
}
.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-left: none;
  border-right: none;
}

/* Style actions to make custom cell look like real cell from DevExtreme */
.dx-datagrid-action {
  position: relative !important;
}

.dx-datagrid-text-content {
  width: 100%;
}

/* WETX PAGER */
.wetx-pager__page-sizes {
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 5px 10px;
  color: #344050;
  font-size: 15px;
}

.wetx-pager__text {
  font-size: 13px;
  color: #344050;
}

.pager-button__next,
.pager-button__back {
  width: 100px;
  height: 30px;
  margin: 0 5px;
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  color: #fff;
  background: #1f77ff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 1.5px rgba(0, 0, 0, 0.07), 0px 2px 5px rgba(43, 45, 80, 0.08),
    0px 0px 0px 1px rgba(43, 45, 80, 0.1);

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.pager-button__next.disabled,
.pager-button__back.disabled {
  opacity: 0.7;
  cursor: not-allowed;
  box-shadow: none;
  color: #344050;
  background: #e5e5ea;
}

.pager-button__next:hover,
.pager-button__back:hover {
  box-shadow: none;
}

/* DATE RANGE FILTER */
.date-range__container {
  position: relative;
  overflow: visible;
}

.date-range__picker-container {
  padding: 10px 15px !important;
}

/* REACTSTRAP MODAL */
.react-select-fix {
  max-width: 20% !important;
  height: 40px;
  margin: 10px 0;
}

.react-select-fix .select__control > * {
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.divider {
  width: 1px;
  background-color: #edf2f8;
  height: 60px;
}

.resize-modal {
  width: 90vw !important;
}

.modal-xs {
  width: 45vw !important;
}

@media (max-width: 576px) {
  .modal-xs {
    width: 90vw !important;
  }
}

.modal-dialog {
  max-width: 100% !important;
}

.modal.show .modal-dialog {
  transform: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

.share-modal__header-text {
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #4d5969;
}

.share-modal__container {
  background: #ffffff;
  box-shadow: 0px 7px 14px rgba(65, 69, 88, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.07);
  border-radius: 8px !important;
  padding: 20px 40px;
}

.hor-divider__share {
  height: 1px;
  width: 100%;
  color: #d8e2ee;
}

.share-modal__actions {
  color: #2a7be4;
  font-size: 18px;
}

.share-modal__actions-dismiss {
  cursor: pointer;
}

.share-modal__actions-dismiss:hover {
  background: linear-gradient(#2a7be4, #00d9ff);
  background: -webkit-linear-gradient(#2a7be4, #00d9ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.share-modal__actions-copy {
  cursor: pointer;
  font-weight: 700;
}

.share-modal__actions-copy:hover {
  background: linear-gradient(#2a7be4, #00d9ff);
  background: -webkit-linear-gradient(#2a7be4, #00d9ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100%;
    margin: 1.75rem auto;
  }
}

/* WETX UPLOADER */
/* Tag select */
.tag-select__container {
  position: relative;
  width: 10%;
  height: 40px;
  margin: 10px 0;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 3px;
  line-height: 40px;
  padding: 0 10px;
}

.tag-select__action {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.tag-select__tags-container {
  position: absolute;
  top: 10;
  left: 0;
  border: 1px solid #b6c2d2;
  background: #ffffff;
  box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 10px 20px;
  z-index: 1;
}

.tag-select__tags {
  max-height: 300px;
  overflow-y: scroll;
}

.tag-select__tag {
  width: 100%;
  margin-bottom: 10px;
  min-width: 250px;
}

.tag-select__checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #9da8ba;
  border-radius: 4px;
  cursor: pointer;
}

.tag-select__checkbox-label {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.tag-select__empty {
  min-width: 200px;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px;
}

.hor-divider {
  height: 1px;
  width: 100%;
}

.add-tag__button {
  height: 40px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  color: #333333;
  font-weight: 600;
}

.add-tag__button:hover {
  text-decoration: underline;
}

/* Single select */
.uploader-single-select__container {
  position: relative;
  width: 20%;
  height: 40px;
  margin: 10px 0;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 3px;
  line-height: 40px;
  padding: 0 10px;
  color: #000000;
  transition: all 0.5s;
}

.single-file .uploader-single-select__container {
  width: 40%;
  user-select: none;
  text-align: center;
}

.single-select__action {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.single-select__label {
  position: absolute;
  top: 0;
  transition: all 0.5s;
}

.single-select__label.move-label {
  top: -30px;
}

.single-select__options-container {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  border: 1px solid #b6c2d2;
  background: #ffffff;
  box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 10px 20px;
  z-index: 1098;
  max-height: 300px;
  overflow-y: scroll;
}

.single-select__options {
  margin-bottom: 10px;
}

.single-select__option {
  width: 100%;
  line-height: 20px;
  font-weight: 400;
  white-space: nowrap;
  max-width: 500px;
  cursor: pointer;
}

.single-select__option:hover {
  text-decoration: underline;
}

.single-select__checkmark {
  margin-left: 10px;
  font-weight: 900;
}

/* MULTI SELECT */
/* Tag select */
.multi-select__container {
  position: relative;
  width: 40%;
  height: 40px;
  margin: 0;
  margin-bottom: 5px;
  background: #ffffff;
  border: 1px solid #d8e2ef;
  border-radius: 3px;
  line-height: 40px;
  padding: 0 5px;
  user-select: none;
  font-size: 0.8333em;
  letter-spacing: 0.02em;
  color: #4d5969;
  font-weight: 600;
}

.multi-select__filter {
  width: 100%;
  height: 40px;
  margin: 0;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 3px;
  line-height: 40px;
  padding: 0 10px;
}

.multi-select__action {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.multi-select__items-container {
  position: absolute;
  top: 10;
  left: 0;
  border: 1px solid #b6c2d2;
  background: #ffffff;
  box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 10px 20px;
}

.multi-select__items {
  max-height: 200px;
  overflow-y: scroll;
}

.multi-select__item {
  width: 100%;
  margin-bottom: 10px;
  min-width: 250px;
}

.multi-select__checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #9da8ba;
  border-radius: 4px;
  cursor: pointer;
}

.multi-select__checkbox-label {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.multi-select__empty {
  min-width: 200px;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px;
}

/* single select */
.single-select__container {
  position: relative;
  width: 70%;
  height: 40px;
  margin: 0;
  margin-bottom: 5px;
  background: #ffffff;
  border: 1px solid #d8e2ef;
  border-radius: 3px;
  line-height: 40px;
  padding: 0 5px;
  user-select: none;
  font-size: 0.8333em;
  letter-spacing: 0.02em;
  color: #4d5969;
  font-weight: 600;
}

.single-select__container.error {
  border: 1px solid red;
}

.single-select__error {
  position: absolute;
  top: 30px;
  left: 30%;
  color: red;
  font-size: 12px;
  font-weight: 400;
}

.single-select__filter {
  width: 100%;
  height: 40px;
  margin: 0;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  border-radius: 3px;
  line-height: 40px;
  padding: 0 10px;
}

.single-select__action {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.single-select__items-container {
  position: absolute;
  top: 10;
  left: 0;
  border: 1px solid #b6c2d2;
  background: #ffffff;
  box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 10px 20px;
}

.single-select__items {
  max-height: 200px;
  overflow-y: scroll;
}

.single-select__item {
  width: 100%;
  margin-bottom: 10px;
  min-width: 250px;
}

.single-select__checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #9da8ba;
  border-radius: 4px;
  cursor: pointer;
}

.single-select__checkbox-label {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.single-select__empty {
  min-width: 200px;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px;
}
/* DOC EDIT */
.modal-view .modal-content {
  max-height: 90vh;
  height: 90vh;
  overflow-y: scroll;
  max-width: 100% !important;
}

.modal-lg {
  width: 80vw !important;
}

.doc-edit__container {
  background: #eeeeee;
  width: 100%;
  padding: 10px 0 10px 50px;
}

.doc-edit__info-spec {
  margin-left: 50px;
  height: 100%;
}

.doc-edit__text {
  margin: 0;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 14px;
  line-height: 30px;
}

.doc-edit__text > * {
  margin-right: 5px;
}

.doc-edit__text .uploader-single-select__container {
  width: 70%;
  height: 30px;
  line-height: 30px;
  margin-left: auto;
}

.doc-edit__actions {
  margin-right: 5px;
}

.doc-edit__btn {
  margin: 0;
  cursor: pointer;
  font-size: 16px;
  color: #1f77ff;
  font-weight: 500;
  line-height: 16px;
}

.doc-edit__info-spec__tags {
  margin-left: 5px;
}

.date-range {
  margin-left: auto;
  width: 70%;
}

.date-range .react-datepicker-wrapper {
  height: 30px;
  border: 1px solid #e5e5ea;
  border-radius: 3px;
  width: 100%;
  overflow: hidden;
}
.react-datepicker__input-container,
.react-datepicker__input-container .date-range {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.doc-edit__tag-button {
  cursor: pointer;
  background: #2a7be4;
  color: #fff;
  padding: 0 10px;
  border-radius: 3px;
}

.doc-edit__tag-button:hover {
  box-shadow: 0px 7px 14px rgba(65, 69, 88, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.07);
}

/* REACT PDF */
.react-pdf__Document,
.react-pdf__Page,
.react-pdf__Page__canvas,
.react-pdf__Page__textContent {
  width: 90% !important;
  height: 75vh !important;
  border: none;
  margin: 0 auto;
}

.react-pdf__Page__svg {
  height: 100% !important;
}

.modal-lg {
  width: 60vw !important;
  height: 70% !important;
}

.modal-open {
  width: 100vw !important;
  height: 90vh !important;
}

.modal-footer {
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center !important;
}

/* WETX ICON */
.wetx-icon {
  padding: 0 !important;
}

.wetx-icon__red {
  filter: invert(14%) sepia(96%) saturate(7400%) hue-rotate(1deg) brightness(107%) contrast(112%);
  cursor: pointer;
}

.wetx-icon__red:hover {
  filter: invert(20%) sepia(99%) saturate(6318%) hue-rotate(342deg) brightness(89%) contrast(92%);
}

.wetx-icon__green {
  filter: invert(33%) sepia(94%) saturate(611%) hue-rotate(76deg) brightness(102%) contrast(86%);
  cursor: pointer;
}

.wetx-icon__green:hover {
  filter: invert(35%) sepia(83%) saturate(278%) hue-rotate(40deg) brightness(90%) contrast(91%);
}

.wetx-icon__blue {
  filter: invert(38%) sepia(99%) saturate(767%) hue-rotate(187deg) brightness(91%) contrast(97%);
  cursor: pointer;
}

.wetx-icon__blue:hover {
  filter: invert(19%) sepia(43%) saturate(4768%) hue-rotate(234deg) brightness(63%) contrast(142%);
}

.wetx-icon__gray {
  filter: invert(56%) sepia(10%) saturate(691%) hue-rotate(169deg) brightness(92%) contrast(90%);
  cursor: pointer;
}

.wetx-icon__gray:hover {
  filter: invert(42%) sepia(4%) saturate(7%) hue-rotate(314deg) brightness(95%) contrast(87%);
}

.wetx-icon__black {
  filter: invert(0%) sepia(5%) saturate(0%) hue-rotate(348deg) brightness(103%) contrast(104%);
  cursor: pointer;
}

.wetx-icon__black:hover {
  filter: invert(56%) sepia(10%) saturate(691%) hue-rotate(169deg) brightness(92%) contrast(90%);
}

.wetx-icon__white {
  filter: brightness(0) invert(1);
  cursor: pointer;
  box-sizing: content-box;
}

.wetx-icon__white:hover {
  filter: brightness(0) saturate(100%);
  filter: invert(8%) sepia(100%) saturate(7098%) hue-rotate(245deg) brightness(106%) contrast(133%);
}

@media only screen and (max-width: 1200px) {
  .modal-lg {
    width: 80vw !important;
  }
}

@media only screen and (max-width: 1000px) {
  .modal-lg {
    width: 85vw !important;
    max-width: 100% !important;
  }
}

/* WETX CONFIRMATION BOX */
.react-confirm-alert {
  z-index: 1100 !important;
}
.react-confirm-alert-overlay {
  z-index: 1100 !important;
  background: rgba(46, 49, 49, 0.5);
}

.custom-ui {
  background: #fff;
  padding: 57px 47px;
  box-shadow: 0px 7px 14px rgba(65, 69, 88, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  max-width: 600px;
  z-index: 1100 !important;
}

.confirm-title {
  color: #4d5969;
}

.confirm-divider {
  height: 1px;
  background: #d8e2ee;
  width: 100%;
  margin: 10px 0;
}

.confirm-desc {
  font-size: 16px;
  color: #5d6e82;
}

.custom-secondary {
  background: #fff !important;
  border: 1px solid #2a7be4 !important;
  color: #2a7be4 !important;
}

.custom-secondary:hover {
  color: #616161 !important;
  border: 1px solid #616161 !important;
}

/* WETx TAGS */
.tag-management {
  width: 50vw !important;
}
.modal-header__padding {
  padding: 30px !important;
}

.hor-divider {
  height: 1px;
  width: 100%;
  background: #b2b2b2;
}

.tags-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 480px;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
}
.tags-input:focus-within {
  border: 1px solid #0052cc;
}

input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
}
input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: fit-content;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 10px;
  margin: 5px 8px 0 0;
}

.tag-circles {
  position: relative;
  height: 20px;
}

.tag-circle {
  position: absolute;
  left: -10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.tag-circle:hover {
  border: 1px solid #e4e4e4 !important;
  box-sizing: border-box;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}

.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  line-height: 14px;
  font-size: 14px;
  margin-left: 8px;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 800;
}

.tag {
  position: relative;
}

.color-picker {
  position: absolute;
  z-index: 1098;
  top: 30px;
  background: #fff;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.display-arrow {
  display: block;
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-top-color: #e4e4e4;
  border-left-color: #e4e4e4;
  position: absolute;
  top: -5px;
  transform: rotate(45deg);
  background: #fff;
  -webkit-transform: rotate(45deg);
}

.color-picker-check {
  position: absolute;
  top: 3px;
  left: 5px;
  font-size: 10px;
  line-height: 13px;
  font-weight: 600;
}

.tag-name-input {
  position: absolute;
  height: 40px;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 5px;
  border: 1px solid #d8e2ee;
  box-sizing: border-box;
  border-radius: 6px;
}

.tag-name__input-clear {
  position: absolute;
  right: 5px;
  top: 12px;
  background: #000;
  color: #fff;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  line-height: 15px;
  font-weight: 600;
  cursor: pointer;
}

.tag-name__input-clear:hover {
  background: rgba(0, 0, 0, 0.25);
}

.system-tags-overlay {
  position: absolute;
  z-index: 1097;
  left: 20px;
  top: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 15px 20px 10px 20px;
}

.system-tags-container {
  height: 200px;
  overflow-y: scroll;
}

.system-tags-overlay .system-tags-button {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 4px;
}

.system-tags-overlay .system-tags-button:hover {
  border: 1px solid #b2b2b2;
}

.tagging__actions-close {
  background: #e5e5ea;
  border: 1px solid #e5e5ea;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 1.5px rgba(0, 0, 0, 0.07), 0px 2px 5px rgba(43, 45, 80, 0.08),
    0px 0px 0px 1px rgba(43, 45, 80, 0.1);
  border-radius: 3px;
  padding: 5px 40px;
  cursor: pointer;
  margin-right: 10px;
}

.tagging__actions-close:hover {
  box-shadow: none;
  border: 1px solid #e4e4e4;
}

.tagging__actions-save {
  text-align: center;
  background: #1f77ff;
  color: #ffffff;
  border: 1px solid #1f77ff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 1.5px rgba(0, 0, 0, 0.07), 0px 2px 5px rgba(43, 45, 80, 0.08),
    0px 0px 0px 1px rgba(43, 45, 80, 0.1);
  border-radius: 3px;
  padding: 5px 40px;
  cursor: pointer;
}

.tagging__actions-save:hover {
  box-shadow: none;
}

.tagging-actions__button-txt {
  margin: 0;
}

@media screen and (max-width: 1100px) {
  .tag-management {
    width: 60vw !important;
  }
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }

  .tag-management {
    width: 90vw !important;
  }
}

/* WETx WATER */
.input__container {
  position: relative;
}

.input__title {
  position: absolute;
  left: 5px;
  top: 20px;
  transition: all 0.5s;
}

.input__title.date {
  top: 10px;
  z-index: 1;
}

.input__title.fix-center {
  top: 10px;
}

.input__title.focused {
  top: -15px;
}

.input__title.date.focused {
  top: -20px;
}

.input__title.fix-center.focused {
  top: -20px;
}

/* Additional Permissions */
.additional-permission__item-container {
  color: #4d5969;
  font-weight: 600;
  font-size: 15px;
  user-select: none;
}
.additional-permission__item-container.alt {
  background: #f3f4ee;
}

/* SEARCH BOX */

.search-box-actions {
  position: absolute;
  top: 0;
  right: 30px;
  padding: 3px;
}

.search-box-button__button {
  height: 30px;
  font-size: 16px !important;
  line-height: 20px !important;
  padding: 0.3125rem !important;
  margin-right: 10px;
}

.search-box-button__icon {
  color: #fff;
  margin-right: 3px;
}

/* SEARCH RESULTS */
.search-results__fail {
  padding: 10px;
}

.search-results__fail .title {
  font-size: 18px;
  font-weight: 600;
}

.search-results__fail .desc {
  font-size: 14px;
}

.rpv-core__text-layer {
  opacity: 0.5 !important;
}

.rpv-search__highlight {
  background-color: #12b0e8 !important;
}

.multi-actions-modal .modal-content {
  overflow-y: visible;
}
